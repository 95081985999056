













































import { Vue, Component, Watch } from 'vue-property-decorator';
import { H3Icon, H3Button } from '@h3/thinking-ui';
import { Avatar } from '@h3/antd-vue';
import { removeStorage } from '@WorkPlatform/common/utils';
import { LoginService } from '@WorkPlatform/services';

@Component({
  name: 'userCenter',
  components: {
    H3Icon,
    H3Button,
    AAvatar: Avatar,
  },
})
export default class AppRecords extends Vue {
  appLoading: boolean = false;

  teamNum: number = 1;

  /**
   * 当前选择的组织
   */
  curOrg: any = {};

  @Watch('appLoading')
  showToast (show: boolean) {
    if (show) {
      this.$toast.show({
        icon: 'loading',
        text: '加载中',
      });
    } else {
      this.$toast.hide();
    }
  }

  /**
   * 获取用户姓名
   */
  get userInfo () {
    let userInfo: any = localStorage.getItem('userInfo');
    if (userInfo) {
      userInfo = JSON.parse(userInfo) || {};
      return userInfo;
    }
    return {
      name: '-',
      mobile: '-',
    };
  }

  /**
   * 跳转修改密码页面
   */
  gotToChangePassword () {
    this.$router.push({
      name: 'm-work-platform-change-password',
      params: {
        mobile: this.userInfo.mobile,
      },
    });
  }

  getOrgDetail () {
    LoginService.getDataSource().then((res: any) => {
      if (res.success && res.data) {
        this.curOrg = res.data;
      }
    });
  }

  async loginOut () {
    this.$modal.confirm({
      title: '',
      content: '确定要退出登录吗？',
      onConfirm: async () => {
        removeStorage();
        const res: any = await LoginService.logout();

        if (!res.success) return;
        this.$router.push({
          name: 'm-work-platform-login',
        });
      },
      onCancel () {
      },
    });
  }

  async mounted () {
    this.getOrgDetail();
  }
}
